import styles from './Heading.module.scss';
import type { ReactNode } from 'react';

type Props = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | null;
  className?: string | null;
  children: ReactNode;
};

const Heading = ({ as, className, children }: Props) => (
  <div className={`${styles.heading} ${as || ''} ${className || ''}`}>
    {children}
  </div>
);

export default Heading;

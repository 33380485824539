import { useCallback } from 'react';
import FullScreenPopup from '../FullScreenPopup';
import type { ReactNode, KeyboardEvent } from 'react';

type Props = {
  children: ReactNode;
  onClose: () => void;
  onKeyDown?: (key: string) => void;
  className?: string;
};

const Popup = ({ children, onKeyDown, onClose, className }: Props) => {
  const handleKeyDown = useCallback((e: KeyboardEvent) => onKeyDown && onKeyDown(e.key), [onKeyDown]);

  return <FullScreenPopup onKeyDown={handleKeyDown} hide={onClose} className={className}>{children}</FullScreenPopup>;
};

export default Popup;

import { memo } from 'react';
import { toLocaleDate, toLongLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';

type Props = {
  value: Date | string | number | null;
  longFormat?: boolean;
};

const DateDisplay = ({ value, longFormat }: Props) => {
  const culture = useCultureName();

  return longFormat
    ? toLongLocaleDate(value, culture)
    : toLocaleDate(value, culture);
};

export default memo(DateDisplay);

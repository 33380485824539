import type { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react';
import type { Size } from '../Button';
import ButtonCore from '../Button';

type Props = {
  children?: ReactNode;
  size?: Size;
  noIcon?: boolean;
  icon?: ReactElement | false;
  className?: string;
};

const Button = ({ children, size = 'md', noIcon, icon, className, ...props }: Props & ButtonHTMLAttributes<HTMLButtonElement>) => {
  if (noIcon !== undefined)
    console.error('"noIcon" property of "Button" has been deprecated and will be removed after 2022-08-10.\nUse "icon" property with passed "false" value instead.');

  return (
    <ButtonCore
      className={className}
      size={size}
      icon={icon !== undefined ? icon : noIcon ? false : undefined}
      {...props}
    >
      {children}
    </ButtonCore>
  );
};

export default Button;
